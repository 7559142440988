<template>
  <div class="bmanagement">
    <div class="bmana_center">
      <header>
        <div class="header_img">
          <img src="../../assets/index/logo.png" alt />
          <!-- <img :src="roleInfo.imgurl" alt=""> -->
          <div class="logo_title">
            <p class="logo_one">朝阳云教研</p>
            <p class="logo_two">—INTEGRATION—</p>
          </div>
        </div>
        <div class="header_user">
          <div class="user_img">
            <img src="../../assets/components/people.png" alt />
          </div>
          <p>
            <span>{{roleInfo.userName}}</span>
          </p>
          <div>
            <el-button type="text" size="mini" @click="outsystem">
              <i class="outicon"></i>
              <span class="outtext">退出</span>
            </el-button>
          </div>
        </div>
      </header>
      <main>
        <leftsub @showindex="topathindex"></leftsub>
        <rightfrom :currentIndex="activeIndex"></rightfrom>
      </main>
    </div>
  </div>
</template>
<script>
import leftsub from "./leftsub.vue";
import rightfrom from "./rightfrom.vue";
export default {
  data() {
    return {
      // 角色信息
      roleInfo: null,
      // 接收的信息
      //左边导航传过来的index
      activeIndex: null,
      // 右边需要的数据
    };
  },
  created() {
    this.queryRole();
  },
  methods: {
    // 获取角色信息
    queryRole() {
      // let aa = {
      //   id: 1, //角色id
      //   rmark: "111", //
      //   roleName: "刘老师", //角色名称
      // };
      // this.roleInfo = aa;
      // return;
      this.$Http.getUserMsg().then((res) => {
        console.log(res, "登录用户信息");
        if (res.status == 200) {
          // if (res.data == null) {
          //   this.$cookies.delCookie("JSESSIONID");
          // } else {
            this.roleInfo = res.data;
          // }
        }
      });
    },

    //
    //
    outsystem() {
      this.$confirm("确认退出管理系统吗？", "退出系统", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$router.push({
            name: "1",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 接收的信息
    // 接收接收左侧导航栏当前激活的信息
    topathindex(data) {
      console.log(data, "当前激活的index");
      this.activeIndex = data;
    },
    //
  },
  components: {
    leftsub,
    rightfrom,
  },
};
</script>
<style lang="scss" scoped>
.bmanagement {
  .bmana_center {
    width: 100%;
    background: #f3f4f7;
    header {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      padding: 17px 30px 14px;
      .header_img {
        display: flex;
        justify-content: flex-start;
        .logo_title {
          margin-left: 5px;
          width: 200px;
          .logo_one {
            // width: 192px;
            // height: 53px;
            font-size: 36px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            // line-height: 53px;
            // letter-spacing: 2px;
          }
          .logo_two {
            // width: 125px;
            // height: 26px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 26px;
          }
        }
      }
      .header_user {
        display: flex;
        justify-content: space-between;
        .user_img {
          width: 48px;
          margin-top: 16px;
        }
        p {
          height: 80px;
          line-height: 80px;
          margin: 0 15px;
          // width: 54px;
          // height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          // line-height: 21px;
        }
        .el-button {
          height: 50px;
          margin-top: 15px;
          .outicon {
            display: inline-block;
            width: 21px;
            height: 21px;
            background: url("../../assets/icon/out.png") no-repeat;
            vertical-align: middle;
          }
          .outtext {
            display: inline-block;
            height: 21px;
            line-height: 21px;
            vertical-align: middle;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
    main {
      display: flex;
      justify-content: space-between;
      height: 100vh;
      width: 100%;
      overflow-y: auto;
      background: #f3f4f7;
      margin-top: 3px;
      .leftsub {
        width: 200px;
        min-height: 745px;
        background-color: #fff;
      }
      .rightfrom {
        width: calc(100% - 220px);
      }
    }
  }
}
</style>